/* .register-form {
  text-align: center;
}

.register-form-control .MuiInputBase-input, .register-form-control .MuiFormLabel-root {
  color: #FFFFFF;
}

.register-form-control .MuiInputBase-input:after {
  border bottom
}

.register-form-control .MuiFormLabel-root.Mui-focused {
  color: #d3d3d3;

} */
.register-form .MuiTypography-subtitle1 {
  margin-top: 15px;
  margin-bottom: -20px;
}

.register-form {
  padding: 25px;
  display: flex;
  align-items: center;
}
.MuiFormControl-root {
  width: 100%;
}

.register-form .fixed-button {
  position: fixed;
  top: 10px;
  left: 12px;
  z-index: 999;
}

.button-container {
  border-top: 1px solid #333;
  justify-content: flex-end;
  padding: 12px;
}

.groups-container .title {
  margin-bottom: 50px;
}