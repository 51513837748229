.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

.groups-container .group-button {
  margin: 15px;
}

.img-holder {
  height: 100vh;
  overflow: hidden;
  position: relative;
}
.form-container {
  height: 100vh;
  overflow-y: scroll;
}

@keyframes bganimation {
  0%   { left:0px; }
  50%   { left:-1000px; }
  100% { left:0px; }
}



.groups-container {
  height: 100vh;
  /* overflow-y: scroll; */
  display: flex;
}

.bg {
  height: 100%;
  position: absolute;
  animation: bganimation 120s linear infinite;
}

.mask {
  top: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.30)
}
.wrapper {
  position: relative;
}
.logo {
  width: 90%;
  max-width: 700px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.9;
}

.goto-form {
  position: absolute;
  top:80%;
  left: 50%;
  transform:translateX(-50%);
}

@media (min-width: 600px) {
  .goto-form {
    display: none;
  }
}